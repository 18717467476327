<template>
  <div class="allianceBusiness-box">
    <div class="top-box">
      <p class="top-title">合作共赢</p>
    </div>
    <div class="main-frame">
      <p class="main-title">加盟招商</p>
      <div class="main-group">
        <div class="item">
          <div class="txt">
            <h3>诚招</h3>
            <p>
              诚招志同道合的伙伴加盟“<span>领惠供应链营销中心</span>”。领惠供应链通过多年来<br>
              耕耘，在客户和市场上积累了良好的口碑，对现有品牌拥有完整的知识产权。
            </p>
          </div>
        </div>
        <div class="item">
          <div class="txt">
            <p>
              <span style="display: block;text-indent: 2em;">公司拥有具有市场竞争力的供应链解决方案，特别针对环太平洋航线以及</span>
              欧地航线拥有多款产品，市场推广潜力巨大。同时依靠完整的国外代理网络，<br>
              形成较强的市场发掘能力。<br>
              <span style="display: block;text-indent: 2em;">公司通过云端管理，实现了操作中心运作。既确保了高质量的服务，又为</span>
              加盟伙伴节省了操作流程的巨大成本。<br>
              <span style="display: block;text-indent: 2em;">如果您认同供应链管理行业的发展前景，具有良好的商业信誉、社会关系</span>
              有一定的市场开拓以及经营经验；有长期稳健经营的投资心态和良好的风险意<br>
              识；诚实友善、笃信合作，能遵纪守法，文明经营；具有强烈的创富愿望，渴<br>
              望通过合作快速取得事业成功，长久获得理想的社会效益和经济效益。<br>
              <span style="display: block;text-indent: 2em;">我们竭诚邀请您加盟领惠供应链大家庭。</span>
            </p>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="main-frame">-->
<!--      <div class="main-group">-->
<!--        <p class="main-title">加盟招商</p>-->
<!--        <p class="main-text">FRANCHISEE</p>-->
<!--        <img class="publicity-img" src="@/assets/allianceBusiness/publicityImg.png">-->
<!--      </div>-->
<!--      <div class="desc-group">-->
<!--        <p class="desc-text"></p>-->
<!--        <p class="desc-text desc-text-placeholder">1</p>-->
<!--        <p class="desc-text">领惠供应链通过多年来耕耘，在客户和市场上积累了良好的口碑，对现有品</p>-->
<!--        <p class="desc-text">牌拥有完整的知识产权。</p>-->
<!--        <p class="desc-text desc-text-placeholder">1</p>-->
<!--        <p class="desc-text">公司拥有具有市场竞争力的供应链解决方案，特别针对环太平洋航线以及欧</p>-->
<!--        <p class="desc-text">地航线拥有多款产品，市场推广潜力巨大。同时依靠完整的国外代理网络，</p>-->
<!--        <p class="desc-text">形成较强的市场发掘能力。</p>-->
<!--        <p class="desc-text desc-text-placeholder">1</p>-->
<!--        <p class="desc-text">公司通过云端管理，实现了操作中心运作。既确保了高质量的服务，又为加盟</p>-->
<!--        <p class="desc-text">伙伴节省了操作流程的巨大成本。</p>-->
<!--        <p class="desc-text desc-text-placeholder">1</p>-->
<!--        <p class="desc-text">如果您认同供应链管理行业的发展前景，具有良好的商业信誉、社会关系有一</p>-->
<!--        <p class="desc-text">定的市场开拓及经营经验；有长期稳健经营的投资心态和良好的风险意识；诚</p>-->
<!--        <p class="desc-text">实友善、笃信合作，能遵纪守法，文明经营；具有强烈的创富愿望，渴望通过</p>-->
<!--        <p class="desc-text">合作快速取得事业成功，长久获得理想的社会效益和经济效益。</p>-->
<!--        <p class="desc-text desc-text-placeholder">1</p>-->
<!--        <p class="desc-text">我们竭诚邀请您加盟领惠供应链大家庭。</p>-->
<!--      </div>-->
<!--    </div>-->
    <div class="apply-frame">
      <p class="apply-title">加盟申请</p>
      <p class="apply-text">加盟申请和个人申请均请发送至邮箱</p>
      <p class="apply-text" style="margin-bottom: 60px;">info@marshalfreight.net</p>
      <input v-model="params.name" class="apply-input" value="" placeholder="姓名">
      <input v-model="params.phone" class="apply-input" value="" placeholder="电话">
      <input v-model="params.email" class="apply-input" value="" placeholder="邮箱">
      <input v-model="params.city" class="apply-input" value="" placeholder="行政地域">
      <p @click="submitLeagueApply" class="apply-btn">确定</p>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  props: [],
  components: {

  },
  data () {
    return {
      params: {
        name: '',
        phone: '',
        email: '',
        city: ''
      }
    }
  },
  created () {

  },
  mounted () {
    this.$store.commit('setPageNow', 'allianceBusiness')
  },
  methods: {
    submitLeagueApply () { // 提交加盟申请
      if (!this.params.name) {
        this.$layer.msg('请输入姓名')
        return
      }
      if (!this.params.phone) {
        this.$layer.msg('请输入电话')
        return
      }
      if (!this.params.email) {
        this.$layer.msg('请输入邮箱')
        return
      }
      if (!this.params.city) {
        this.$layer.msg('请输入行政地域')
        return
      }
      this.axios.get(`http://admin.marshalfreight.cn/APP/SubmitBusiness?name=${this.params.name}&phone=${this.params.phone}&email=${this.params.email}&city=${this.params.city}`).then(data => {
        if (data.data) {
          this.$layer.msg(data.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style scoped>
  .top-box{
    width: 1920px;
    height: 620px;
    /*background-image: url('../assets/home/swiper/10.jpg');*/
    /*background-image: url('../assets/home/swiper/13.jpg');*/
    /*background-image: url('../assets/home/swiper/15.jpg');*/
    /*background-image: url('../assets/home/swiper/16.jpg');*/
    /*background-image: url('../assets/home/swiper/17.jpg');*/
    background-image: url('../assets/home/swiper/3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }
  .bg-img{
    width: 100%;
    height: 100%;
  }
  .top-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 160px;
    padding-left: 230px;
    box-sizing: border-box;
  }
  .top-title{
    padding-top: 282.5px;
    text-align: center;
    font-size: 55px;
    line-height: 55px;
    letter-spacing: 0.3em;
    font-family: Source Han Sans CN;
    color: #FFFFFF;
  }
  .top-text{
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 45px;
  }
  .main-frame{
    /*padding-top: 100px;*/
    /*display: flex;*/
    /*padding-bottom: 80px;*/
  }
  .main-title{
    padding-top: 90px;
    padding-bottom: 60px;
    font-size: 45px;
    font-weight: 400;
    text-align: center;
  }
  .main-group{
    display: block;
    background-image: url(../assets/allianceBusiness/southeast.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center center;
  }
  .main-group:before{
    position: absolute;
    top: 0;
    transition: all 0.5s;
    content: "";
    z-index: 1;
    /*border-right: 700px solid rgba(247, 247, 247, 0.9);*/
    /*border-bottom: 350px solid rgba(0, 97, 227, 0.8);*/
    /*transform: rotate(*/
    /*  90deg*/
    /*) translate(175px, 350px);*/
    /*border-right: 352px solid rgba(247, 247, 247, 0.9);*/
  border-right: 352px solid rgba(236,62,4,0.7);
    border-top: 700px solid rgba(0, 97, 227, 0.8);
    transform: translateX(-176px);
    left: 50%;
    width: 0;
    height: 0;
    box-sizing: border-box;
  }
  .main-group:after {
    content: "";
    display: block;
    clear: both;
    box-sizing: border-box;
  }
  .main-group>.item{
    position: relative;
    z-index: 2;
    width: 50%;
    float: left;
    height: 700px;
    text-align: center;
    cursor: pointer;
    color: #333;
  }
  .main-group>.item:first-child:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 175px;
    background: rgba(0, 97, 227, 0.8);
    transition: all 0.5s;
  }
  .main-group>.item:last-child:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 175px;
    /*background-color: rgba(247, 247, 247, 0.9);*/
  background-color: rgba(236,62,4,0.7);
    transition: all 0.5s;
    z-index: 1;
  }
  .main-group>.item:first-child .txt {
    color: #fff;
  }
  .main-group .item .txt {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 8;
  }
  .main-group .item .txt h3 {
    display: block;
    position: relative;
    font-size: 40px;
    font-weight: 400;
    padding-bottom: 24px;
    line-height: 1.1;
    color: inherit;
  }
  .main-group .item .txt h3:after{
    position: absolute;
    content: "";
    bottom: 0;
    width: 1.6em;
    background: #fff;
    height: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
  .main-group .item .txt p{
    color: white;
    padding-top: 20px;
    font-size: 18px;
    line-height: 1.8;
    width: 612px;
    margin: 0 auto;
    text-align: left;
  }
  .main-group .item:first-child .txt p {
    color: rgba(255, 255, 255, 0.8);
    width: 612px;
    margin: 0 auto;
    text-align: right;
  }
  .main-text{
    margin-left: 146px;
    margin-top: 20px;
    margin-bottom: 70px;
    font-size: 40px;
    font-family: SourceHanSansCN;
    font-weight: 800;
    color: #363636;
    line-height: 40px;
  }
  .publicity-img{
    width: 691px;
    height: 464px;
  }
  .desc-group{
    margin-top: -12px;
    margin-left: 134px;
  }
  .desc-text{
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #363636;
    line-height: 46px;
  }
  .desc-text-placeholder{
    opacity: 0;
    line-height: 15px;
  }
  .apply-frame{
    /*width: 1920px;*/
    /*height: 862px;*/
    /*background-color: #3468A9;*/
    /*padding-top: 86px;*/
    /*box-sizing: border-box;*/
    height: 690px;
    padding:86px 0;
    /*background-image: url("../assets/careerPlanning/southeast2.jpg");*/
    background-image: url("../assets/allianceBusiness/2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .apply-title{
    font-size: 45px;
    font-weight: 400;
    font-family: PingFang SC;
    color: #FFFFFF;
    line-height: 80px;
    text-align: center;
    margin-bottom: 30px;
  }
  .apply-text{
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 28px;
    text-align: center;
  }
  .apply-input{
    display: block;
    margin: 0 auto 20px;
    width: 450px;
    height: 46px;
    background: #FFFFFF;
    /*border-radius: 4px;*/
    padding: 0 27px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 46px;
    font-family: PingFang SC;
    font-weight: 400;
    color: black;
    outline: none;
    border: none;
  }
  .apply-input::placeholder{
    color: #999999;
  }
  .apply-btn{
    margin: 50px auto 0;
    width: 200px;
    height: 46px;
    background: rgb(25,118,210);
    /*border-radius: 3px;*/
    text-align: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 46px;
    cursor: pointer;
  }
</style>
