<template>
  <div class="careerPlanning-box">
    <div class="top-box">
      <p class="top-title">诚聘英才</p>
      <p class="top-text">为企业找英才,为人才找名企<span style="visibility: hidden">占位符</span>领惠大家庭欢迎您的加入</p>
    </div>
<!--    <div class="top-box">-->
<!--      <img class="bg-img" src="@/assets/careerPlanning/bgImg.png">-->
<!--      <div class="top-content">-->
<!--        <p class="top-title">加入我们</p>-->
<!--        <p class="top-text">为企业找英才，为人才找名企</p>-->
<!--        <p class="top-text">领惠大家庭欢迎您的加入</p>-->
<!--      </div>-->
<!--    </div>-->
<!--        <div class="main-frame">-->
<!--          <p class="main-title">诚聘英才</p>-->
<!--          <div class="main-content">-->
<!--            <div class="content-group">-->
<!--              <p class="main-desc">领惠供应链正在快速发展期，在海、空运业务操作，航线采购，</p>-->
<!--              <p class="main-desc">财务会计等岗位上我们求贤若渴，诚挚邀请优秀的您加入领惠供应链大家庭。</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--    <div class="main-frame">-->
<!--      <img class="main-bg" src="@/assets/careerPlanning/mainBg.png">-->
<!--      <div class="main-content">-->
<!--        <div>-->
<!--          <p class="main-title">诚聘英才</p>-->
<!--          <p class="main-text">LOOKING FOR TALENT</p>-->
<!--        </div>-->
<!--        <div style="margin-left: 230px;">-->
<!--          <p class="main-desc">领惠供应链正在快速发展期，在海、空运业务操作，航线采购，</p>-->
<!--          <p class="main-desc" style="margin-top: 30px;">财务会计等岗位上我们求贤若渴，诚挚邀请优秀的您加入领惠供应链大家庭。</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="apply-frame">
      <p class="apply-title">个人申请</p>
      <p class="apply-text">加盟申请和个人申请均请发送至邮箱</p>
      <p class="apply-text" style="margin-bottom: 60px;">info@marshalfreight.net</p>
      <div class="inpu-frame">
        <div class="input-group">
          <input v-model="params.name" class="apply-input" value="" placeholder="姓名">
          <input v-model="params.phone" class="apply-input" value="" placeholder="电话">
          <input v-model="params.email" class="apply-input" value="" placeholder="邮箱">
          <input v-model="params.city" class="apply-input" value="" placeholder="期望工作的城市">
        </div>
        <div class="add-group">
          <input id="fileUpload" class="file-upload" type="file" accept="application/msword" @change="imgUpload"/>
          <div @click="uploadFile" v-if="fileUploadStatus==0" class="upload-group">
            <img class="fileAdd-icon" src="@/assets/careerPlanning/fileAddIcon.png">
            <p class="upload-tip-text">简历发送</p>
          </div>
          <div v-if="fileUploadStatus==1" class="upload-group">
            <img class="fileAdd-icon" style="width: 55px;height: 55px;" src="@/assets/careerPlanning/videoloadicon.gif">
            <p class="upload-tip-text">上传中</p>
          </div>
          <div @click="uploadFile" v-if="fileUploadStatus==2" class="upload-group">
            <img class="fileAdd-icon" style="width:144px;height: 181px;" src="@/assets/careerPlanning/uploadFinishIcon.png">
            <p class="upload-tip-text">简历上传完成</p>
          </div>
        </div>
      </div>
      <p @click="submitPersonApply" class="apply-btn">确定</p>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  components: {

  },
  data () {
    return {
      params: {
        name: '',
        phone: '',
        email: '',
        city: '',
        fileid: ''
      },
      fileUploadStatus: 0 // 0:未上传 1:上传中 2:上传完成
    }
  },
  created () {

  },
  mounted () {
    this.$store.commit('setPageNow', 'careerPlanning')
  },
  methods: {
    uploadFile () { // 文件上传
      const fileUpload = document.getElementById('fileUpload')
      fileUpload.click()
    },
    imgUpload: function () { // 上传简历
      if (!event.target.files || !event.target.files[0]) {
        return
      }
      const formData = new FormData()
      formData.append('Pic', event.target.files[0])
      this.fileUploadStatus = 1
      this.axios.post('http://admin.marshalfreight.cn/APP/UploadFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        const fileUpload = document.getElementById('fileUpload')
        fileUpload.value = ''
        if (response.data.code === 1) {
          this.fileUploadStatus = 2
          this.params.fileid = response.data.msg
        } else {
          this.fileUploadStatus = 0
          this.$layer.msg(response.data.msg)
        }
      }).catch((response) => {
        console.log(response)
      })
    },
    submitPersonApply () { // 提交加盟申请
      if (!this.params.name) {
        this.$layer.msg('请输入姓名')
        return
      }
      if (!this.params.phone) {
        this.$layer.msg('请输入电话')
        return
      }
      if (!this.params.email) {
        this.$layer.msg('请输入邮箱')
        return
      }
      if (!this.params.city) {
        this.$layer.msg('请输入期望工作的城市')
        return
      }
      if (!this.params.fileid) {
        this.$layer.msg('请上传简历')
        return
      }
      if (this.fileUploadStatus === 1) {
        this.$layer.msg('请等待简历上传完毕')
        return
      }
      this.axios.get(`http://admin.marshalfreight.cn/APP/SubmitUser?name=${this.params.name}&phone=${this.params.phone}&email=${this.params.email}&city=${this.params.city}&fileid=${this.params.fileid}`).then(data => {
        if (data.data) {
          this.$layer.msg(decodeURI(data.data.msg))
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style scoped>
  .top-box{
    width: 1920px;
    height: 620px;
    background-image: url('../assets/home/southeast4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .bg-img{
    width: 100%;
    height: 100%;
  }
  .top-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 162px;
    padding-left: 205px;
    box-sizing: border-box;
  }
  .top-title{
    padding-top: 282.5px;
    text-align: center;
    font-size: 55px;
    line-height: 55px;
    letter-spacing: 0.3em;
    font-family: Source Han Sans CN;
    color: #FFFFFF;
  }
  .top-text{
    font-size: 18px;
    padding-top: 1.2em;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
  }
  .main-frame{
    /*position: relative;*/
    /*width: 1920px;*/
    /*height: 910px;*/
  }
  .main-bg{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 1920px;
    height: 540px;
  }
  .main-content{
    background-image: url(../assets/careerPlanning/bg2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-top: 110px;
    padding-bottom: 80px;
  }
  .content-group{
    margin: 0 auto;
    width: 840px;
    font-size: 18px;
    line-height: 1.8;
    text-align: center;
    color: #fff;
  }
  .main-title{
    padding-top: 90px;
    padding-bottom: 60px;
    font-size: 45px;
    font-weight: 400;
    text-align: center;
  }
  .main-text{
    margin-top: 20px;
    font-size: 40px;
    font-family: SourceHanSansCN;
    font-weight: 800;
    color: #363636;
    line-height: 40px;
  }
  .main-desc{
    /*font-size: 30px;*/
    /*font-family: PingFang SC;*/
    /*font-weight: 400;*/
    /*color: #363636;*/
    /*line-height: 30px;*/
  }
  .apply-frame{
    height: 690px;
    padding:86px 0;
    background-image: url("../assets/careerPlanning/about_bg2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
  }
  .apply-title{
    font-size: 45px;
    font-weight: 400;
    font-family: PingFang SC;
    line-height: 80px;
    text-align: center;
    margin-bottom: 30px;
    color: black;
  }
  .apply-text{
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 28px;
    text-align: center;
  }
  .inpu-frame{
    margin: 58px 0 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .apply-input{
    display: block;
    outline: none;
    border: none;
    width: 387px;
    height: 46px;
    /*background-color: #F2F4F7;*/
    background-color: #FFFFFF;
    /*border-radius: 4px;*/
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: black;
    line-height: 56px;
    margin-bottom: 15px;
    padding: 0 27px;
    box-sizing: border-box;
  }
  .apply-input::placeholder{
    color: #999999;
  }
  .apply-input:last-of-type{
    margin-bottom: 0;
  }
  .add-group{
    margin-left: 40px;
    width: 200px;
    height: 229px;
    /*background-color: #F2F4F7;*/
    background-color: #FFFFFF;
    /*border-radius: 4px;*/
  }
  .file-upload{
    display: none;
  }
  .upload-group{
    width: 200px;
    height: 229px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .fileAdd-icon{
    width: 74px;
    height: 74px;
    margin: 0 auto;
  }
  .upload-tip-text{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 18px;
    margin-top: 20px;
    text-align: center;
  }
  .apply-btn{
    width: 280px;
    height: 44px;
    background: rgb(25,118,210);
    /*border-radius: 4px;*/
    text-align: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 44px;
    margin: 0 auto;
    cursor: pointer;
  }
</style>
