import Vue from 'vue'
// http请求
import './plugins/axios'
import App from './App.vue'
// 页面路由管理
import router from './router'
// 全局状态管理
import store from './store'
// layerUI
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css'
// vue-i18n
import VueI18n from 'vue-i18n'
// swiper
import 'swiper/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
Vue.use(VueI18n)
Vue.prototype.$layer = layer(Vue)

Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en', // 从本地存储中取，如果没有默认为中文
  messages: {
    zh: require('./assets/languages/zh.json'),
    en: require('./assets/languages/en.json'),
    es: require('./assets/languages/es.json')
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
