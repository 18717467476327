import { render, staticRenderFns } from "./careerPlanning.vue?vue&type=template&id=5a05a2b6&scoped=true&"
import script from "./careerPlanning.vue?vue&type=script&lang=js&"
export * from "./careerPlanning.vue?vue&type=script&lang=js&"
import style0 from "./careerPlanning.vue?vue&type=style&index=0&id=5a05a2b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a05a2b6",
  null
  
)

export default component.exports