import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import freightTrack from '../views/freightTrack.vue'
import allianceBusiness from '../views/allianceBusiness.vue'
import careerPlanning from '../views/careerPlanning.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/freightTrack',
    name: 'freightTrack',
    component: freightTrack
  },
  {
    path: '/allianceBusiness',
    name: 'allianceBusiness',
    component: allianceBusiness
  },
  {
    path: '/careerPlanning',
    name: 'careerPlanning',
    component: careerPlanning
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
