<template>
  <div class="home">
    <div class="banner-frame">
      <swiper class="banner-bg" :options="swiperOption">
        <swiper-slide><img src="@/assets/home/swiper/1.jpg"></swiper-slide>
        <swiper-slide><img src="@/assets/home/swiper/2.png"></swiper-slide>
<!--        <swiper-slide><img src="@/assets/home/swiper/3.jpg"></swiper-slide>-->
<!--        <swiper-slide><img src="@/assets/home/swiper/4.jpg"></swiper-slide>-->
<!--        <swiper-slide><img src="@/assets/home/swiper/10.jpg"></swiper-slide>-->
        <swiper-slide><img src="@/assets/home/swiper/12.jpg"></swiper-slide>
<!--        <swiper-slide><img src="@/assets/home/swiper/13.jpg"></swiper-slide>-->
<!--        <swiper-slide><img src="@/assets/home/swiper/15.jpg"></swiper-slide>-->
<!--        <swiper-slide><img src="@/assets/home/swiper/16.jpg"></swiper-slide>-->
<!--        <swiper-slide><img src="@/assets/home/swiper/17.jpg"></swiper-slide>-->
      </swiper>
<!--      <swiper ref="mySwiper" class="banner-bg" :options="swiperOptions">-->
<!--        <swiper-slide style="height: 100%;"><img src="@/assets/home/bannerImg3.jpg"></swiper-slide>-->
<!--        <swiper-slide style="height: 100%;"><img src="@/assets/home/bannerImg4.jpg"></swiper-slide>-->
<!--      </swiper>-->
      <div v-if="langType=='zh'" class="banner-group">
        <p class="banner-title">领惠供应链</p>
        <p class="banner-text">MARSHAL FREIGHT SUPPLY CHAIN SERVICE LIMITED</p>
      </div>
      <div v-if="langType=='en'" class="banner-group">
        <p class="banner-title">MARSHAL FREIGHT</p>
        <p class="banner-title" style="font-size: 50px;">SUPPLY CHAIN SERVICE LIMITED</p>
      </div>
      <div v-if="langType=='es'" class="banner-group">
        <p class="banner-title">MARSHAL FREIGHT</p>
        <p class="banner-title" style="font-size: 50px;">SUPPLY CHAIN SERVICE LIMITED</p>
      </div>
      <div class="search-box">
          <div class="search-group">
            <img class="search-icon" src="@/assets/home/searchIcon.png">
            <p style="font-size: 24px;color: black;margin-right: 10px;">LH</p>
            <input v-model="caseNumber" @focus="inputTipShow(true)" @blur="inputTipShow(false)" class="search-input" value="" />
            <img class="input-tip" v-if="langType=='zh' & tipImgShow" src="@/assets/home/inputTip1_1.png">
            <img class="input-tip" v-if="langType=='en' & tipImgShow" src="@/assets/home/inputTip2_1.png">
            <img class="input-tip" v-if="langType=='es' & tipImgShow" src="@/assets/home/inputTip3_1.png">
          </div>
          <p @click="cargoTracking" v-if="langType=='zh'" class="select-btn">货运跟踪</p>
          <p @click="cargoTracking" v-if="langType=='en'" class="select-btn">TRACKING</p>
          <p @click="cargoTracking" v-if="langType=='es'" class="select-btn">CONSULTA</p>
      </div>
    </div>
    <div class="intro-frame">
      <div>
        <img class="intro-img" src="../assets/home/southeast3.jpg" />
        <div class="intro-content">
          <p v-if="langType=='zh'" class="intro-title">简介</p>
          <p v-if="langType=='en'" class="intro-title">BRIEF INTRODUCTION</p>
          <p v-if="langType=='es'" class="intro-title">INTRODUCCIÓN</p>
          <p v-if="langType=='zh'" class="intro-text">公司成立于2002年，经过多年的发展，我们已经成为一家服务于全供应链的综合服务供应商。</p>
          <p v-if="langType=='en'" class="intro-text">Marshal Freight was founded in 2002. After years of development, we have become a comprehensive service provid er serving the whole supply chain.</p>
          <p v-if="langType=='es'" class="intro-text">Marshal Freight fue fundada en el año 2002, y tras muchos años de continuo desarrollo nos hemos convertido en un proveedor integral de servicios abocados a atender la cadena de suministro a nivel mundial.</p>
        </div>
      </div>
      <div>
        <div class="intro-content intro-content2">
          <p v-if="langType=='zh'" class="intro-title">理念</p>
          <p v-if="langType=='en'" class="intro-title">OUR VALUE</p>
          <p v-if="langType=='es'" class="intro-title">NUESTROS VALORES</p>
          <p v-if="langType=='zh'" class="intro-text">我们凭借革新的精神，以专业且富有热情的服务，为客户提供多样化且可靠的供应链解决方案。</p>
          <p v-if="langType=='en'" class="intro-text">Rely on the spirit of innova tion, professional and en thusiastic service, Marshal Freight provides customers with diversified and reliable supply chain solutions.</p>
          <p v-if="langType=='es'" class="intro-text">Con espíritu innovador y profesionalidad&nbsp;, ofrecemos a nuestros clientes diversas y confiables soluciones para atender sus cadenas de suministro.</p>
        </div>
        <img class="intro-img" src="../assets/home/699pic_04cl5c_xy2.jpg" />
      </div>
      <div>
        <img class="intro-img" src="../assets/home/southeast2.jpg" />
        <div class="intro-content intro-content3">
          <p v-if="langType=='zh'" class="intro-title">服务</p>
          <p v-if="langType=='en'" class="intro-title">SERVICE CONTENT</p>
          <p v-if="langType=='es'" class="intro-title">CONTENIDO DEL SERVICIO</p>
          <p v-if="langType=='zh'" class="intro-text">我们的服务包括：贸易海运、空运、陆运、报关、仓储及为贸易达成提供各类支持。</p>
          <p v-if="langType=='en'" class="intro-text">Our services include International Trading, Importation/Exportation by Sea FCL/LCL, Air or Rail, Domestic Logistics,Customs Declaration, Third Party Ware-housing and Trade Accessory Support.</p>
          <p v-if="langType=='es'" class="intro-text">Nuestros servicios abarcan el Comercio Internacional, el Transporte Marítimo, Aéreo y Ferroviario de Importación y Exportación, la Logística Interna, Despachos de Aduana,el Almacenamiento y Asesoramiento.</p>
        </div>
      </div>
    </div>
    <div v-if="langType=='zh'" class="documents-frame">
      <img class="documents-bg" src="@/assets/home/documentsBg.png">
      <div class="documents-content">
        <p class="documents-text" style="text-align: center;font-size: 60px;">外贸单证小灵通</p>
        <p class="documents-text">外贸单据制作专家</p>
        <p @click="fabrication" class="documents-btn">一键制作外贸单据 >></p>
      </div>
    </div>
    <div class="relation-frame">
      <p v-if="langType=='zh'" class="relation-title">联系我们</p>
      <p v-if="langType=='en'" class="relation-title">CONTACT US</p>
      <p v-if="langType=='es'" class="relation-title">CONTÁCTENOS</p>
      <div v-if="langType=='zh'" class="relation-way-frame">
        <div class="relation-way-group">
          <p class="relation-way-title">地址</p>
          <p class="relation-way-text">上海市虹口区花园路128号T街区A座351室</p>
        </div>
        <div class="relation-way-group">
          <p class="relation-way-title">电话</p>
          <p class="relation-way-text">+86-21-63079687</p>
        </div>
        <div class="relation-way-group">
          <p class="relation-way-title">传真</p>
          <p class="relation-way-text">+86-21-63090180</p>
        </div>
        <div class="relation-way-group">
          <p class="relation-way-title">邮件</p>
          <p class="relation-way-text">info@marshalfreight.net</p>
        </div>
      </div>
      <div v-if="langType=='en'" class="relation-way-frame">
        <div class="relation-way-group">
          <p class="relation-way-title">ADDRESS</p>
          <p class="relation-way-text">Room 351/A Block T No.128 Huayuan Road Shanghai 200083 People’s Republic of China</p>
        </div>
        <div class="relation-way-group">
          <p class="relation-way-title">TEL</p>
          <p class="relation-way-text">+86-21-63079687</p>
        </div>
        <div class="relation-way-group">
          <p class="relation-way-title">FAX</p>
          <p class="relation-way-text">+86-21-63090180</p>
        </div>
        <div class="relation-way-group">
          <p class="relation-way-title">E-mail</p>
          <p class="relation-way-text">info@marshalfreight.net</p>
        </div>
      </div>
      <div v-if="langType=='es'" class="relation-way-frame">
        <div class="relation-way-group">
          <p class="relation-way-title">DIRECCIÓN</p>
          <p class="relation-way-text">128 t 351 / A, Shanghai City Park Road,República Popular China</p>
        </div>
        <div class="relation-way-group">
          <p class="relation-way-title">TEL</p>
          <p class="relation-way-text">+86-21-63079687</p>
        </div>
        <div class="relation-way-group">
          <p class="relation-way-title">FAX</p>
          <p class="relation-way-text">+86-21-63090180</p>
        </div>
        <div class="relation-way-group">
          <p class="relation-way-title">E-mail</p>
          <p class="relation-way-text">info@marshalfreight.net</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {

  },
  data () {
    return {
      langType: '',
      caseNumber: '',
      tipImgShow: true,
      swiperOption: {
        autoplay: {
          delay: 8000
        },
        loop: true
      }
    }
  },
  watch: {
    '$i18n.locale' (newValue, oldValue) {
      this.langType = newValue
    }
  },
  mounted () {
    this.langType = this.$i18n.locale
    this.$store.commit('setPageNow', 'Home')
  },
  methods: {
    fabrication () { // 一键制作外贸单据
      window.open('http://www.valavale.com')
    },
    cargoTracking () { // 货运跟踪
      if (this.caseNumber) {
        this.$router.push({ path: '/freightTrack', query: { caseNumber: 'LH' + this.caseNumber } })
      } else {
        if (this.langType === 'en') {
          this.$layer.msg('Enter bill of LADING/CASE number/job number')
        } else if (this.langType === 'es') {
          this.$layer.msg('Número de conocimiento de embarque/número de caja/...')
        } else {
          this.$layer.msg('输入提单号/箱号/工作号')
        }
      }
    },
    inputTipShow (focus) { // 是否显示输入提示
      if (focus) {
        this.tipImgShow = false
      } else {
        if (!this.caseNumber.length) {
          this.tipImgShow = true
        }
      }
    }
  }
}
</script>
<style scoped>
  @font-face{
    font-family: 'fontsize1';
    src : url('../assets/font/fontsize1.woff');
  }
  @font-face{
    font-family: 'fontsize2';
    src : url('../assets/font/fontsize2.woff');
  }
  .banner-frame{
    position: relative;
    width: 1920px;
    height: 990px;
    overflow: hidden;
  }
  .banner-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .banner-bg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .banner-group{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 9;
  }
  .banner-title{
    text-align: center;
    font-size: 70px;
    font-family: fontsize3;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 70px;
  }
  @font-face{
    font-family: 'fontsize3';
    src : url('../assets/font/fontsize3.woff');
  }
  .banner-text{
    margin-top: 36px;
    font-size: 40px;
    font-family: fontsize3;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 40px;
  }
  .search-box{
    position: absolute;
    left: 50%;
    /*top: 60%;*/
    top: 78%;
    display: flex;

    transform: translateX(-50%);
    width: 979px;
    height: 88px;

    width: auto;
    z-index: 9;
  }
  .intro-frame{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0 0;
  }
  .intro-img{
    width: 450px;
    height: 300px;
    object-fit: cover;
  }
  .intro-bg{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .intro-content{
    width: 450px;
    height: 300px;
    background-image: url("../assets/home/48c5056.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 40px 30px 48px;
    box-sizing: border-box;
    color: #fff;
  }
  .intro-content2{
    color: #18274e;
    background-image: url("../assets/home/e28e61d.jpg");
  }
  .intro-content3{
    background-image: url("../assets/home/img-index-07.jpg");
  }
  .intro-title{
    line-height: 30px;
    font-size: 20px;
    font-family: 微软雅黑;
    margin-bottom: 22px;
    font-weight: 400;
  }
  .intro-line{
    width: 120px;
    height: 4px;
    background: #FFFFFF;
    /*border-radius: 2px;*/
    margin-top: 40px;
    margin-bottom: 97px;
  }
  .intro-text{
    font-size: 16px;
    line-height: 1.8;
  }
  .idea-frame{
    position: relative;
    width: 1920px;
    height: 907px;
  }
  .idea-bg{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .idea-content{
    position: absolute;
    left: 79px;
    top: 0;
    width: 573px;
    height: 100%;
    background-color: #EB3C00;
    padding-left: 90px;
    box-sizing: border-box;
  }
  .idea-title{
    margin-top: 172px;
    font-size: 80px;
    font-family: fontsize3;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 80px;
  }
  .idea-line{
    width: 120px;
    height: 4px;
    background: #FFFFFF;
    /*border-radius: 2px;*/
    margin-top: 39px;
    margin-bottom: 100px;
  }
  .idea-text{
    width: 389px;
    height: 274px;
    font-size: 36px;
    font-family: fontsize2;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 60px;
  }
  .serve-frame{
    position: relative;
    width: 1920px;
    height: 706px;
  }
  .serve-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .serve-content{
    position: absolute;
    top: 0;
    right: 0;
    width: 702px;
    height: 100%;
    background-color: #24201C;
    padding-right: 136px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
  }
  .serve-title{
    margin-top: 106px;
    text-align: right;
    font-size: 80px;
    font-family: fontsize3;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 80px;
  }
  .serve-line{
    width: 120px;
    height: 4px;
    background: #008FD1;
    /*border-radius: 2px;*/
    margin-top: 40px;
    margin-bottom: 97px;
  }
  .serve-text{
    font-size: 36px;
    font-family: fontsize2;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 66px;
    text-align: right;
  }
  .documents-frame{
    position: relative;
    width: 1920px;
    height: 405px;
    background-color: #253551;
    margin-top: 80px;
  }
  .documents-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .documents-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .documents-text{
    width: 600px;
    height: 80px;
    text-align: justify;
    font-size: 68px;
    font-family: SourceHanSansCN;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 80px;
    margin: 0 auto;
  }
  .documents-text::after{
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
  .documents-btn{
    margin: 32px auto 0;
    width: 320px;
    height: 60px;
    background: #008FD1;
    /*border-radius: 3px;*/
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
  }
  .relation-frame{
    width: 1920px;
    padding-bottom: 90px;
    box-sizing: border-box;
  }
  .relation-title{
    padding-top: 90px;
    font-size: 45px;
    font-family: fontsize3;
    font-weight: 400;
    color: #000;
    text-align: center;
  }
  .relation-way-frame{
    margin: 60px auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1400px;
  }
  .relation-way-group{
    position: relative;
    width: 342.5px;
    height: 230px;
    background-color: rgba(248, 248, 248, 1);
    transition: all 0.5s;
    padding: 25px;
    box-sizing: border-box;
    color: #333;
  }
  .relation-way-group:before{
    position: absolute;
    width: 58px;
    height: 58px;
    right: 20px;
    bottom: 20px;
    background: url('../assets/home/icon-lx-1.png') no-repeat center center;
    background-size: contain;
    content:"";
  }
  .relation-way-group:hover{
    background-color: #0061e3;
    color: #FFFFFF;
  }
  .relation-way-group:hover:before{
    background: url('../assets/home/icon-lx-1s.png') no-repeat center center;
    background-size: contain;
  }
  .relation-way-group:nth-child(2):before,.relation-way-group:nth-child(3):before{
    width: 64px;
    background: url('../assets/home/icon-lx-2.png') no-repeat center center;
  }
  .relation-way-group:nth-child(2):hover:before,.relation-way-group:nth-child(3):hover:before{
    background: url('../assets/home/icon-lx-2s.png') no-repeat center center;
    background-size: contain;
  }
  .relation-way-group:nth-child(4):before{
    background: url('../assets/home/icon-lx-4.png') no-repeat center center;
  }
  .relation-way-group:nth-child(4):hover:before{
    background: url('../assets/home/icon-lx-4s.png') no-repeat center center;
    background-size: contain;
  }
  .relation-way-title{
    font-size: 20px;
    padding-bottom: 10px;
    font-family: fontsize1;
    font-weight: 600;
  }
  .relation-way-text{
    font-size: 16px;
    font-family: fontsize2;
  }
  .search-group{
    width: 979px;
    height: 88px;
    background: #FFFFFF;
    /*border-radius: 3px;*/
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    position: relative;
  }
  .search-icon{
    width: 26px;
    height: 26px;
    margin-right: 13px;
  }
  .search-input{
    width: 800px;
    height: 88px;
    font-size: 24px;
    font-family: fontsize2;
    font-weight: bold;
    line-height: 88px;
    color: black;
    outline: none;
    border: none;
  }
  .input-tip{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 102px;
    pointer-events: none;
  }
  .select-btn{
    /*margin:20px auto 0;*/
    margin-left: 20px;
    width: 240px;
    /*height: 58px;*/
    height: 88px;
    background-color: rgb(25,118,210);
    /*border-radius: 3px;*/
    font-size: 26px;
    font-family: fontsize3;
    font-weight: 500;
    color: #FFFFFF;
    /*line-height: 58px;*/
    line-height: 88px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
  }
  .select-btn:hover{
    background-color: rgb(71,145,219);
  }
</style>
