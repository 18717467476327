import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageNow: 'Home' // 当前页面
  },
  mutations: {
    setPageNow (state, data) { // 设置当前页面
      state.pageNow = data
    }
  },
  actions: {
  },
  modules: {
  }
})
