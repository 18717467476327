<template>
  <div id="app" :style="{zoom:scaleValue}">
    <navigation></navigation>
    <router-view/>
    <footerDom v-if="footerShow"></footerDom>
  </div>
</template>
<script>
import navigation from '@/components/navigation.vue'
import footerDom from '@/components/footer.vue'
import { mapState } from 'vuex'
export default {
  name: '',
  components: {
    navigation,
    footerDom
  },
  computed: {
    ...mapState(['pageNow'])
  },
  data () {
    return {
      scaleValue: 1,
      footerShow: true, // 是否显示footerDom组件
      footerHeight: true // 是否增加footerDom组件的height值
    }
  },
  watch: {
    pageNow (oldValue, newValue) {
      if (this.pageNow === 'Home') {
        this.footerShow = true
        this.footerHeight = true
      } else if (this.pageNow === 'freightTrack') {
        this.footerShow = false
        this.footerHeight = false
      } else {
        this.footerShow = true
        this.footerHeight = false
      }
    }
  },
  mounted () {
    this.changeScale()
    window.onresize = () => { // 监听屏幕尺寸变化
      this.changeScale()
    }
  },
  methods: {
    changeScale () { // 改变屏幕缩放比例
      // console.log(document.body.clientHeight)
      const clientWidth = document.body.clientWidth
      this.scaleValue = clientWidth / 1920
    }
  }
}
</script>
<style lang="scss">
*{
  margin: 0;
  padding: 0;
  -moz-user-select:none; /*火狐*/
  -webkit-user-select:none; /*webkit浏览器*/
  -ms-user-select:none; /*IE10*/
  -khtml-user-select:none; /*早期浏览器*/
  user-select:none;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", 微软雅黑, Tahoma, Arial, sans-serif;
}
html,body{
  width: 100%;
  height: 100%;
}
img{
  display: block;
}
#app{
  transform-origin: left top;
  width: 1920px;
  height: 100%;
}
</style>
