<template>
  <div class="freightTrack-box">
    <div class="cargo-msg">
      <img class="bg-img" src="@/assets/freightTrack/bgImg.png" />
      <div class="cargo-content">
        <div v-if="langType == 'zh'">
          <p class="cargo-title">货物跟踪</p>
          <p class="cargo-title2">CARGO TRACKING</p>
        </div>
        <div v-if="langType == 'en'">
          <p class="cargo-title">CARGO</p>
          <p class="cargo-title" style="margin-top: 10px;">TRACKING</p>
        </div>
        <div v-if="langType == 'es'">
          <p class="cargo-title">SEGUIMIENTO</p>
          <p class="cargo-title" style="margin-top: 10px;">DE CARGAS</p>
        </div>
        <div>
          <p class="order-number">{{ dataCode }}</p>
          <p class="order-msg">
            {{ $t("lang.freightTrack.orderMsg.statusKey") }}：{{
              dataList.count
                ? showType == 0
                  ? $t(
                      `lang.freightTrack.modelMsg.statusList[${dataList.count -
                        1}]`
                    )
                  : $t(
                      `lang.freightTrack.modelMsg.statusList[${dataList.count +
                        5}]`
                    )
                : ""
            }}
          </p>
          <!--          <p class="order-msg">{{$t('lang.freightTrack.orderMsg.addressKey')}}：</p>-->
          <p class="order-msg">
            {{ $t("lang.freightTrack.orderMsg.timeKey") }}：{{
              dataList.count
                ? `${dataList.data[dataList.count - 1].dateContent} ${
                    dataList.data[dataList.count - 1].timeContent
                  }`
                : ""
            }}
          </p>
          <!-- <p @click="lookDetail" v-if="langType=='zh'" class="order-detail-btn">详情</p> -->
        </div>
      </div>
    </div>
    <div class="overview-frame">
      <p class="overview-title">
        {{ $t("lang.freightTrack.modelMsg.modelKey") }}
      </p>
      <div class="progress-frame">
        <p class="progress-date-title">
          {{ $t("lang.freightTrack.modelMsg.dateKey") }}
        </p>
        <div v-if="showType == 0" class="progress-content">
          <div class="progress-group">
            <p
              v-if="dataList.count >= 1"
              class="finish-line"
              style="width: 67px;"
            ></p>
            <p
              v-else
              class="finish-line"
              style="width: 67px;background-color: #989999;"
            ></p>
            <div>
              <img
                v-if="dataList.count >= 1"
                class="progress-icon"
                src="@/assets/freightTrack/icon1.png"
              />
              <img
                v-else
                class="progress-icon"
                src="@/assets/freightTrack/icon1_2.png"
              />
              <img
                v-if="dataList.count >= 1"
                class="finish-icon"
                src="@/assets/freightTrack/finishIcon.png"
              />
              <p v-else class="finish-icon"></p>
              <div class="progress-text">
                <p v-if="langType == 'es'" style="opacity: 0;">1</p>
                <p style="opacity: 0;">1</p>
                <p
                  v-if="langType == 'es'"
                  class="progress-text2"
                  style="width: 150px;"
                >
                  {{ $t("lang.freightTrack.modelMsg.statusList[0]") }}
                </p>
                <p v-else class="progress-text2" style="width: 241px;">
                  {{ $t("lang.freightTrack.modelMsg.statusList[0]") }}
                </p>
              </div>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[0].dateContent : "" }}
              </p>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[0].timeContent : "" }}
              </p>
            </div>
          </div>
          <div class="progress-group">
            <p
              v-if="dataList.count >= 2"
              class="finish-line"
              style="width: 240px;"
            ></p>
            <p
              v-else
              class="finish-line"
              style="width: 240px;background-color: #989999;"
            ></p>
            <div>
              <img
                v-if="dataList.count >= 2"
                class="progress-icon"
                src="@/assets/freightTrack/icon2.png"
              />
              <img
                v-else
                class="progress-icon"
                src="@/assets/freightTrack/icon2_2.png"
              />
              <img
                v-if="dataList.count >= 2"
                class="finish-icon"
                src="@/assets/freightTrack/finishIcon.png"
              />
              <p v-else class="finish-icon"></p>
              <div class="progress-text">
                <p v-if="langType == 'es'" style="opacity: 0;">1</p>
                <p style="opacity: 0;">1</p>
                <p
                  v-if="langType == 'es'"
                  class="progress-text2"
                  style="width: 190px;"
                >
                  {{ $t("lang.freightTrack.modelMsg.statusList[1]") }}
                </p>
                <p v-else class="progress-text2" style="width: 241px;">
                  {{ $t("lang.freightTrack.modelMsg.statusList[1]") }}
                </p>
              </div>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[1].dateContent : "" }}
              </p>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[1].timeContent : "" }}
              </p>
            </div>
          </div>
          <div class="progress-group">
            <p
              v-if="dataList.count >= 3"
              class="finish-line"
              style="width: 252px;"
            ></p>
            <p
              v-else
              class="finish-line"
              style="width: 252px;background-color: #989999;"
            ></p>
            <div>
              <img
                v-if="dataList.count >= 3"
                class="progress-icon"
                src="@/assets/freightTrack/icon3.png"
              />
              <img
                v-else
                class="progress-icon"
                src="@/assets/freightTrack/icon3_2.png"
              />
              <img
                v-if="dataList.count >= 3"
                class="finish-icon"
                src="@/assets/freightTrack/finishIcon.png"
              />
              <p v-else class="finish-icon"></p>
              <div class="progress-text">
                <p v-if="langType == 'es'" style="opacity: 0;">1</p>
                <p style="opacity: 0;">1</p>
                <p
                  v-if="langType == 'es'"
                  class="progress-text2"
                  style="width: 241px;white-space: pre;"
                  v-html="'RECEPCIÓN<p>DE MERCADERÍAS</p>'"
                ></p>
                <p v-else class="progress-text2" style="width: 241px;">
                  {{ $t("lang.freightTrack.modelMsg.statusList[2]") }}
                </p>
              </div>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[2].dateContent : "" }}
              </p>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[2].timeContent : "" }}
              </p>
            </div>
          </div>
          <div class="progress-group">
            <p
              v-if="dataList.count >= 4"
              class="finish-line"
              style="width: 240px;"
            ></p>
            <p
              v-else
              class="finish-line"
              style="width: 240px;background-color: #989999;"
            ></p>
            <div>
              <img
                v-if="dataList.count >= 4"
                class="progress-icon"
                src="@/assets/freightTrack/icon4.png"
              />
              <img
                v-else
                class="progress-icon"
                src="@/assets/freightTrack/icon4_2.png"
              />
              <img
                v-if="dataList.count >= 4"
                class="finish-icon"
                src="@/assets/freightTrack/finishIcon.png"
              />
              <p v-else class="finish-icon"></p>
              <div class="progress-text">
                <p v-if="langType == 'es'" style="opacity: 0;">1</p>
                <p style="opacity: 0;">1</p>
                <p
                  v-if="langType == 'es'"
                  class="progress-text2"
                  style="width: 241px;white-space: pre;"
                  v-html="'DESPACHO<p>ADUANERO FINALIZADO</p>'"
                ></p>
                <p v-else class="progress-text2" style="width: 241px;">
                  {{ $t("lang.freightTrack.modelMsg.statusList[3]") }}
                </p>
              </div>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[3].dateContent : "" }}
              </p>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[3].timeContent : "" }}
              </p>
            </div>
          </div>
          <div class="progress-group">
            <p
              v-if="dataList.count >= 5"
              class="finish-line"
              style="width: 240px;"
            ></p>
            <p
              v-else
              class="finish-line"
              style="width: 240px;background-color: #989999;"
            ></p>
            <div>
              <img
                v-if="dataList.count >= 5"
                class="progress-icon"
                src="@/assets/freightTrack/icon5.png"
              />
              <img
                v-else
                class="progress-icon"
                src="@/assets/freightTrack/icon5_2.png"
              />
              <img
                v-if="dataList.count >= 5"
                class="finish-icon"
                src="@/assets/freightTrack/finishIcon.png"
              />
              <p v-else class="finish-icon"></p>
              <div class="progress-text">
                <p v-if="langType == 'es'" style="opacity: 0;">1</p>
                <p style="opacity: 0;">1</p>
                <p class="progress-text2" style="width: 241px;">
                  {{ $t("lang.freightTrack.modelMsg.statusList[4]") }}
                </p>
              </div>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[4].dateContent : "" }}
              </p>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[4].timeContent : "" }}
              </p>
            </div>
          </div>
          <div class="progress-group">
            <p
              v-if="dataList.count >= 6"
              class="finish-line"
              style="width: 262px;"
            ></p>
            <p
              v-else
              class="finish-line"
              style="width: 262px;background-color: #989999;"
            ></p>
            <div>
              <img
                v-if="dataList.count >= 6"
                class="progress-icon"
                src="@/assets/freightTrack/icon6.png"
              />
              <img
                v-else
                class="progress-icon"
                src="@/assets/freightTrack/icon6_2.png"
              />
              <img
                v-if="dataList.count >= 6"
                class="finish-icon"
                src="@/assets/freightTrack/finishIcon.png"
              />
              <p v-else class="finish-icon"></p>
              <div class="progress-text">
                <p v-if="langType == 'es'" style="opacity: 0;">1</p>
                <p style="opacity: 0;">1</p>
                <p class="progress-text2" style="width: 241px;">
                  {{ $t("lang.freightTrack.modelMsg.statusList[5]") }}
                </p>
              </div>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[5].dateContent : "" }}
              </p>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[5].timeContent : "" }}
              </p>
            </div>
            <p
              v-if="dataList.count >= 6"
              class="finish-line"
              style="width: 70px;"
            ></p>
            <p
              v-else
              class="finish-line"
              style="width: 70px;background-color: #989999;"
            ></p>
          </div>
        </div>
        <div v-if="showType == 1" class="progress-content">
          <div class="progress-group">
            <p
              v-if="dataList.count >= 1"
              class="finish-line"
              style="width: 173px;"
            ></p>
            <p
              v-else
              class="finish-line"
              style="width: 173px;background-color: #989999;"
            ></p>
            <div>
              <img
                v-if="dataList.count >= 1"
                class="progress-icon"
                src="@/assets/freightTrack/icon7.png"
              />
              <img
                v-else
                class="progress-icon"
                src="@/assets/freightTrack/icon7_2.png"
              />
              <img
                v-if="dataList.count >= 1"
                class="finish-icon"
                src="@/assets/freightTrack/finishIcon.png"
              />
              <p v-else class="finish-icon"></p>
              <div class="progress-text">
                <p v-if="langType == 'es'" style="opacity: 0;">1</p>
                <p style="opacity: 0;">1</p>
                <p
                  v-if="langType == 'es'"
                  class="progress-text2"
                  style="width: 200px;"
                >
                  {{ $t("lang.freightTrack.modelMsg.statusList[6]") }}
                </p>
                <p v-else class="progress-text2" style="width: 241px;">
                  {{ $t("lang.freightTrack.modelMsg.statusList[6]") }}
                </p>
              </div>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[0].dateContent : "" }}
              </p>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[0].timeContent : "" }}
              </p>
            </div>
          </div>
          <div class="progress-group">
            <p
              v-if="dataList.count >= 2"
              class="finish-line"
              style="width: 569px;"
            ></p>
            <p
              v-else
              class="finish-line"
              style="width: 569px;background-color: #989999;"
            ></p>
            <div>
              <img
                v-if="dataList.count >= 2"
                class="progress-icon"
                src="@/assets/freightTrack/icon6.png"
              />
              <img
                v-else
                class="progress-icon"
                src="@/assets/freightTrack/icon6_2.png"
              />
              <img
                v-if="dataList.count >= 2"
                class="finish-icon"
                src="@/assets/freightTrack/finishIcon.png"
              />
              <p v-else class="finish-icon"></p>
              <div class="progress-text">
                <p v-if="langType == 'es'" style="opacity: 0;">1</p>
                <p style="opacity: 0;">1</p>
                <p class="progress-text2" style="width: 241px;">
                  {{ $t("lang.freightTrack.modelMsg.statusList[7]") }}
                </p>
              </div>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[1].dateContent : "" }}
              </p>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[1].timeContent : "" }}
              </p>
            </div>
          </div>
          <div class="progress-group">
            <p
              v-if="dataList.count >= 3"
              class="finish-line"
              style="width: 564px;"
            ></p>
            <p
              v-else
              class="finish-line"
              style="width: 564px;background-color: #989999;"
            ></p>
            <div>
              <img
                v-if="dataList.count >= 3"
                class="progress-icon"
                src="@/assets/freightTrack/icon8.png"
              />
              <img
                v-else
                class="progress-icon"
                src="@/assets/freightTrack/icon8_2.png"
              />
              <img
                v-if="dataList.count >= 3"
                class="finish-icon"
                src="@/assets/freightTrack/finishIcon.png"
              />
              <p v-else class="finish-icon"></p>
              <div class="progress-text">
                <p v-if="langType == 'es'" style="opacity: 0;">1</p>
                <p style="opacity: 0;">1</p>
                <p
                  v-if="langType == 'es'"
                  class="progress-text2"
                  style="width: 373px;"
                  v-html="'MERCADERÍA O<p>DOCUMENTOS LIBERADOS</p>'"
                ></p>
                <p v-else class="progress-text2" style="width: 373px;">
                  {{ $t("lang.freightTrack.modelMsg.statusList[8]") }}
                </p>
              </div>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[2].dateContent : "" }}
              </p>
              <p class="finish-date">
                {{ dataList.data ? dataList.data[2].timeContent : "" }}
              </p>
            </div>
          </div>
          <p
            v-if="dataList.count >= 3"
            class="finish-line"
            style="width: 183px;"
          ></p>
          <p
            v-else
            class="finish-line"
            style="width: 183px;background-color: #989999;"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import verification from '@/plugins/api.js'
export default {
  name: '',
  props: [],
  components: {},
  data () {
    return {
      showType: 0,
      langType: '',
      dataList: [],
      dataCode: ''
    }
  },
  watch: {
    '$i18n.locale' (newValue, oldValue) {
      this.langType = newValue
    }
  },
  created () {},
  mounted () {
    const params = this.$route.query
    if (params.caseNumber) {
      console.log(params.caseNumber[2])
      if (params.caseNumber[2] === 'B' || params.caseNumber[2] === 'D') {
        this.showType = 1
      }
      this.dataCode = params.caseNumber
      this.getLogisticsInformation(params.caseNumber)
      this.langType = this.$i18n.locale
      this.$store.commit('setPageNow', 'freightTrack')
    } else {
      this.$router.replace({ path: '/' })
    }
  },
  methods: {
    getLogisticsInformation (code) {
      // 获取物流信息
      const verificationMsg = verification(code)
      // const httpUrl = `getTrackingInfo/${code}`
      // this.axios.get('http://stage.cargoware.com/cargoapi/services/forwarding/v2/' + httpUrl, {
      // this.axios.get(httpUrl, {
      this.axios
        .get('http://admin.marshalfreight.cn/App/GetTrackingInfo', {
          // this.axios.get('http://localhost:8010/App/GetTrackingInfo', {
          params: { jobNo: code, ...verificationMsg }
          // headers: {
          //   ...verificationMsg
          // }
        })
        .then(data => {
          if (data.data) {
            this.dataList = data.data
            let count = 0
            if (this.dataList.data) {
              count = 1
              for (let i = 1; i < this.dataList.data.length; i++) {
                if (this.dataList.data[i].updateTime) {
                  console.log(this.dataList.data[i].updateTime)
                  const updateTime = this.format(
                    this.dataList.data[i].updateTime
                  ).split(' ')
                  console.log(updateTime)
                  this.dataList.data[i].dateContent = updateTime[0]
                  this.dataList.data[i].timeContent = updateTime[1]
                  count++
                }
              }
            }
            this.dataList.count = count
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    format (timestamp) {
      const time = new Date(timestamp)
      const y = time.getFullYear()
      const m = time.getMonth() + 1
      const d = time.getDate()
      const h = time.getHours()
      const mm = time.getMinutes()
      const s = time.getSeconds()
      return (
        y +
        '-' +
        this.add0(m) +
        '-' +
        this.add0(d) +
        ' ' +
        this.add0(h) +
        ':' +
        this.add0(mm) +
        ':' +
        this.add0(s)
      )
    },
    lookDetail () {
      window.open('http://console.walltechsystem.com/#/ydw')
    }
  }
}
</script>
<style scoped>
.cargo-msg {
  position: relative;
  width: 1920px;
  height: 412px;
}
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cargo-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 210px;
  padding-right: 232px;
  box-sizing: border-box;
}
@font-face {
  font-family: "fontsize3";
  src: url("../assets/font/fontsize3.woff");
}
.cargo-title {
  font-size: 80px;
  font-family: fontsize3;
  font-weight: 800;
  color: #ffffff;
  line-height: 80px;
}
.cargo-title2 {
  margin-top: 37px;
  font-size: 40px;
  font-family: fontsize3;
  font-weight: 800;
  color: #ffffff;
  line-height: 40px;
}
@font-face {
  font-family: "fontsize1";
  src: url("../assets/font/fontsize1.woff");
}
.order-number {
  font-size: 36px;
  font-family: fontsize1;
  font-weight: bold;
  color: #ffffff;
  line-height: 36px;
  margin-bottom: 28px;
}
.order-msg {
  font-size: 24px;
  font-family: fontsize1;
  font-weight: 500;
  color: #ffffff;
  line-height: 48px;
}
.order-detail-btn {
  width: 200px;
  height: 56px;
  background: #008fd1;
  border-radius: 4px;
  text-align: center;
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 56px;
  cursor: pointer;
}
.overview-title {
  padding: 42px 0 30px 80px;
  font-size: 40px;
  font-family: fontsize3;
  font-weight: 500;
  color: #363636;
  line-height: 40px;
}
.progress-frame {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 118px 0 80px;
}
.progress-date-title {
  font-size: 20px;
  font-family: fontsize3;
  font-weight: 500;
  color: #363636;
  line-height: 20px;
  margin-top: 220px;
}
.progress-content {
  display: flex;
}
.progress-group {
  display: flex;
}
.progress-icon {
  margin: 0 auto;
  width: 115px;
  height: 111px;
}
.finish-line {
  height: 4px;
  background-color: #008fd1;
  margin: 144px -38px 0;
}
.finish-icon {
  margin: 16px auto 21px;
  width: 40px;
  height: 40px;
  background-color: #989999;
  border-radius: 50%;
}
.progress-text {
  position: relative;
  width: 115px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #363636;
  line-height: 24px;
  text-align: center;
  margin-bottom: 16px;
}
.progress-text2 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-family: fontsize3;
}
.finish-date {
  line-height: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #363636;
  text-align: center;
}
</style>
