<template>
  <div
    v-if="screenScroll"
    class="navigation-box"
    style="background-color: white"
  >
    <div @click="backHome" class="logoIcon logoIcon2"></div>
    <div class="navigation-list">
      <div
        v-for="(item, index) in navigationList"
        :key="index"
        class="navigation-item navigation-item2"
        :class="item.option ? 'navigation-item-choose' : ''"
      >
        <p @click="cutPage(index)" v-if="index < 3">{{ $t(item.text) }}</p>
        <div @click="item.show = true" v-else>
          <p>{{ $t(item.text) }}</p>
          <div
            v-if="item.effect == 'win' && item.show"
            class="language-list"
            @mouseleave="item.show = false"
          >
            <img
              @click.stop="cueLanguage(index1)"
              class="language-item"
              v-for="(item1, index1) in languageList"
              :key="index1"
              :style="{
                backgroundColor: langType === item1.name ? '#008FD1' : ''
              }"
              :src="item1.icon"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="navigation-box">
    <div @click="backHome" class="logoIcon"></div>
    <div class="navigation-list">
      <div
        v-for="(item, index) in navigationList"
        :key="index"
        class="navigation-item"
        :class="item.option ? 'navigation-item-choose' : ''"
      >
        <p @click="cutPage(index)" v-if="index < 3">{{ $t(item.text) }}</p>
        <div @click="item.show = true" v-else>
          <p>{{ $t(item.text) }}</p>
          <div
            v-if="item.effect == 'win' && item.show"
            class="language-list"
            @mouseleave="item.show = false"
          >
            <img
              @click.stop="cueLanguage(index1)"
              class="language-item"
              v-for="(item1, index1) in languageList"
              :key="index1"
              :style="{
                backgroundColor: langType === item1.name ? '#008FD1' : ''
              }"
              :src="item1.icon"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'navigationBox',
  computed: {
    ...mapState(['pageNow'])
  },
  data () {
    return {
      langType: 'en',
      navigationList: [
        {
          name: 'Home',
          text: 'lang.navigation.home',
          effect: 'skip',
          option: true
        },
        {
          name: 'allianceBusiness',
          text: 'lang.navigation.alliance',
          effect: 'skip',
          option: false
        },
        {
          name: 'careerPlanning',
          text: 'lang.navigation.career',
          effect: 'skip',
          option: false
        },
        { text: 'lang.navigation.language', effect: 'win', show: false }
      ],
      languageList: [
        { name: 'zh', icon: require('@/assets/navigation/zhLogo.jpg') },
        { name: 'en', icon: require('@/assets/navigation/enLogo.jpg') },
        { name: 'es', icon: require('@/assets/navigation/esLogo.jpg') }
      ],
      screenScroll: false
    }
  },
  watch: {
    pageNow () {
      for (let i = 0; i < this.navigationList.length; i++) {
        if (this.navigationList[i].name === this.pageNow) {
          this.navigationList[i].option = true
        } else {
          this.navigationList[i].option = false
        }
      }
    }
  },
  mounted () {
    this.langType = this.$i18n.locale
    window.onscroll = () => {
      // 监听滚动
      this.onScreenScroll()
    }
  },
  methods: {
    backHome () {
      // 回到首页
      const target = this.navigationList[0]
      if (!target.option) {
        this.$router.push({ name: target.name })
        for (let i = 0; i < this.navigationList.length; i++) {
          this.navigationList[i].option = false
        }
        target.option = true
        this.$store.commit('setPageNow', target.name)
      }
    },
    cutPage (index) {
      // 切换页面
      const target = this.navigationList[index]
      if (target.effect === 'skip' && !target.option) {
        this.$store.commit('setPageNow', target.name)
        this.$router.push({ name: target.name })
        // const scroll = document.documentElement || document.body
        // scroll.scrollTop = 0
      } else if (target.effect === 'win') {
        target.show = !target.show
      }
    },
    cueLanguage (index) {
      // 切换语言
      const target = this.languageList[index]
      this.$i18n.locale = target.name
      this.langType = target.name
      localStorage.setItem('lang', target.name)
      this.navigationList[3].show = false
    },
    onScreenScroll () {
      // 监听屏幕滚动
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      this.screenScroll = scrollTop - 82 > 0
    }
  }
}
</script>

<style scoped>
.navigation-box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 19;
  width: 1920px;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
  box-sizing: border-box;
  /*background-color: rgba(0,0,0,0.6);*/
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #ffffff;
}
.navigation-box:hover {
  background-color: white;
  color: #363636;
}
.logoIcon {
  cursor: pointer;
  width: 298px;
  height: 49px;
  background-image: url("../assets/navigation/logo-4.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.logoIcon2 {
  background-image: url("../assets/navigation/logo-4.png");
}
.navigation-box:hover > .logoIcon {
  background-image: url("../assets/navigation/logo-4.png");
}
.navigation-list {
  display: flex;
  align-items: center;
}
@font-face {
  font-family: "fontsize1";
  src: url("../assets/font/fontsize1.woff");
}
.navigation-item {
  position: relative;
  font-size: 20px;
  font-family: fontsize1;
  font-weight: 400;
  line-height: 22px;
  margin-right: 75px;
  cursor: pointer;
}
.navigation-item2 {
  color: #363636;
}
.navigation-item:last-of-type {
  margin-right: 0;
}
.navigation-item:hover {
  color: #008fd1;
}
.navigation-item-choose {
  /*color: #008FD1;*/
  color: #2cabe6;
}
.language-list {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  width: min-content;
  padding: 5px 0;
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 9;
}
.language-item {
  width: 50px;
  height: auto;
  object-fit: contain;
  padding: 5px 10px;
}
.language-item:hover {
  background-color: #008fd1;
}
.language-item:last-of-type {
  margin-bottom: 0;
}
</style>
