<template>
  <div class="footer-box">
    <div class="footer-group">
      <div class="company-msg" @click="cutPage('Home')">
        <img class="logo-icon" src="@/assets/navigation/logo-4.png" />
        <!--          <p class="company-name">{{$t("lang.footer.nameKey")}}</p>-->
      </div>
      <div class="rests-group">
        <p class="rests-text" style="border: none;cursor:default;">
          {{ $t("lang.footer.titleKey") }}
        </p>
        <p @click="cutPage('Home')" class="rests-text record-text2">
          {{ $t("lang.footer.serve1") }}
        </p>
        <p @click="cutPage('allianceBusiness')" class="rests-text record-text2">
          {{ $t("lang.footer.serve2") }}
        </p>
        <p @click="cutPage('careerPlanning')" class="rests-text record-text2">
          {{ $t("lang.footer.serve3") }}
        </p>
      </div>
      <div class="code-box">
        <img class="code-icon" src="@/assets/footer/codeIcon.jpg" />
        <p class="code-text">扫码关注</p>
      </div>
    </div>
    <div class="record-group">
      <p
        class="record-text record-text2"
        style="cursor:pointer;"
        @click="cutPage('Home')"
      >
        © 领惠（上海）供应链管理有限公司
      </p>
      <div style="display: flex;align-items: center;margin-top: 15px;">
        <p class="record-text">版权所有 © 2020-2021 marshalfreight.cn</p>
        <p class="record-text" style="margin-left: 10px;">
          备案/许可证号: 沪ICP备15014994号-2
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'footerBox',
  props: [],
  components: {},
  data () {
    return {}
  },
  created () {},
  mounted () {},
  methods: {
    cutPage (pageName) {
      if (this.$store.state.pageNow !== pageName) {
        this.$store.commit('setPageNow', pageName)
        this.$router.push({ name: pageName })
      }
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }
}
</script>
<style scoped>
.footer-box {
  width: 1920px;
  background-color: black;
}
.footer-group {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 160px;
  box-sizing: border-box;
}
.company-msg {
  cursor: pointer;
  width: 20%;
}
.logo-icon {
  height: 40px;
}
@font-face {
  font-family: "fontsize3";
  src: url("../assets/font/fontsize3.woff");
}
.company-name {
  margin-top: 15px;
  font-size: 16px;
  font-family: fontsize3;
  font-weight: bold;
  color: #ffffff;
  line-height: 16px;
}
.rests-group {
  display: flex;
  align-items: center;
}
@font-face {
  font-family: "fontsize2";
  src: url("../assets/font/fontsize2.woff");
}
.rests-text {
  font-size: 14px;
  font-family: fontsize2;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.4);
  line-height: 14px;
  cursor: pointer;
  border-left: 1px solid rgba(255, 255, 255, 0.23);
  padding-left: 10px;
  margin-left: 10px;
}
.code-box {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.code-icon {
  width: 90px;
  height: 90px;
}
.code-text {
  width: 90px;
  margin-top: 5px;
  color: white;
  text-align: center;
  font-size: 16px;
}
.record-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 80px;
  border-top: 1px solid rgba(67, 67, 68, 0.34);
}
.record-text {
  font-size: 14px;
  font-family: SourceHanSansCN;
  color: rgba(255, 255, 255, 0.3);
  line-height: 14px;
}
.record-text2:hover {
  color: white;
}
</style>
