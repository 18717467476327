import Base64 from 'crypto-js/enc-base64'
const CryptoJS = require('crypto-js')
export default jobNo => {
  const verificationMsg = {}
  const wallTechDate = new Date().toUTCString()
  // verificationMsg['X-WallTech-Date'] = wallTechDate
  verificationMsg.WallTech = wallTechDate

  // 测试
  // const token = 'test5AdbzO5OEeOpvgAVXUFE0A'
  // const secretAccessKey = '79db9e5OEeOpvgAVXUFWSD'
  // 第一次正式
  const token = 'cargo9855O5ODEDEOPAVXUFE0A'
  const secretAccessKey = 'db985534E3pvgAVXUFWSD'
  // 第二次正式
  // const token = "db985534E3pvgAVXUFWSD";
  // const secretAccessKey = "ark9855O5ODEDEOPAVXUFE0A";

  const method = 'GET'

  // 测试地址
  // const httpUrl = `http://stage.cargoware.com/cargoapi/services/forwarding/v2/getTrackingInfo/${jobNo}`
  // 正式地址
  const httpUrl = `http://api.goodcargo.cn/services/forwarding/v2/getTrackingInfo/${jobNo}`

  const auth = `${method}\n${wallTechDate}\n${httpUrl}`
  const hash = CryptoJS.HmacSHA1(auth, secretAccessKey)
  verificationMsg.Authorization = `WallTech ${token}:${Base64.stringify(hash)}`
  // console.log(verificationMsg)
  return verificationMsg
}
